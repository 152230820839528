/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext } from 'react'
import { useCookies } from 'react-cookie'
import '../../../styles/routes_map/car_table.css'
import { Link } from 'react-router-dom'
import warning_red_icon from '../../../styles/icons/warning_red_icon.svg'

import battery_level_border_on from '../../../styles/icons/battery_level_border_on.svg'
import battery_level_border_off from '../../../styles/icons/battery_level_border_off.svg'
import car_data from '../../../API/car_data'
import lock_open_icon from '../../../styles/icons/lock_open_icon.svg'
import lock_close_icon from '../../../styles/icons/lock_close_icon.svg'
import lock_open_icon_wait from '../../../styles/icons/car_open_warning.svg'
import lock_close_icon_wait from '../../../styles/icons/car_close_warning.svg'
import car_engine_null from '../../../styles/icons/car_engine_null.svg'
import power_off_icon from '../../../styles/icons/power_off_icon.svg'
import power_on_icon from '../../../styles/icons/power_on_icon.svg'
import insurance_warn from '../../../styles/icons/account-file-text-outline-warn.svg'
import insurance_green from '../../../styles/icons/account-file-text-outline-green.svg'
import insurance_red from '../../../styles/icons/account-file-text-outline-red.svg'
import Tippy from '@tippyjs/react/headless'
import ReactLoading from 'react-loading'
import Option_btn_scroll from '../../UI/option_btn_scroll'
import { MapContext } from '../../../context/index'
import map_api from '../../../API/map_api'
import Modal_with_accept from '../../UI/modal_with_accept'
import Btn_defolt from '../../UI/btn_defolt'
import axios_api from '../../../API/axios_api'
import CarRouteKeyModal from './CarRouteKeyModal'

export default function Car_table_item_window_routes(props) {
  const [cookies] = useCookies(['csrftoken'])
  const [carReloadModal, setCarReloadModal] = useState(false)

  const [carRouteModal, setCarRouteModal] = useState(false)
  const [carRouteLoading, setCarRouteLoading] = useState(false)
  const [carRouteKey, setCarRouteKey] = useState('')
  const [carReloadModalStatus, setCarReloadModalStatus] = useState('')

  let { map_object } = useContext(MapContext)

  let car = props.car

  if (car.car_number == null) {
    car.car_number = '-'
  }
  if (car.brand == null) {
    car.brand = '-'
  }
  if (car.model == null) {
    car.model = ''
  }

  const show_route = () => {
    map_object.info_draw_routes.car = props.car
    map_object.info_draw_routes.draw_routes = true
    map_object.info_draw_routes.reload = true
    props.setNavBar_item('routes')
  }

  const open_yandex_location = () => {
    map_object.yandex_location_car = car.car_number

    map_object.setYandex_location(
      'https://yandex.ru/maps/?pt=' +
        car.locations[0].longitude +
        ',' +
        car.locations[0].latitude +
        '&z=18&l=map',
    )
    map_object.setYandex_location_modal(true)
  }

  let status_icons = {}
  if (car.status_icons) {
    status_icons = car.status_icons
  } else {
    status_icons.battery = '0%'
    status_icons.locked = true
    status_icons.online = false
    status_icons.power = false
  }

  function send_command_to_car(e) {
    e.stopPropagation()

    let id = e.currentTarget.getAttribute('data_id')
    let number = e.currentTarget.getAttribute('data_number')
    let command_type = e.currentTarget.getAttribute('data_command_type')
    let command_id = e.currentTarget.getAttribute('data_command_id')

    map_object.send_command_to_car(command_type, command_id, id, number)
  }

  const reloadCar = async () => {
    let get_status_reload_car = await axios_api.get_status_reload_car(cookies.csrftoken, car.id)
    if (get_status_reload_car !== 'error') {
      if (get_status_reload_car.status === 'QUEUED') {
        setCarReloadModalStatus('loading')
        get_status_reload_car_looped()
      } else {
        setCarReloadModalStatus('')
      }
    }
    open_modal_reload()
  }

  const acceptReloadCar = async () => {
    let reload_car = await axios_api.reload_car(cookies.csrftoken, car.id)
    if (reload_car !== 'error') {
      get_status_reload_car_looped()
      setCarReloadModalStatus('loading')
    } else {
      setCarReloadModalStatus('error')
    }
  }

  const get_status_reload_car_looped = async () => {
    let get_status_reload_car = await axios_api.get_status_reload_car(cookies.csrftoken, car.id)
    if (get_status_reload_car !== 'error') {
      if (get_status_reload_car.status === 'QUEUED') {
        setCarReloadModalStatus('loading')
        setTimeout(function () {
          get_status_reload_car_looped()
        }, 5000)
      } else {
        if (get_status_reload_car.status === 'CONFIRMED') {
          setCarReloadModalStatus('success')
          setTimeout(function () {
            close_modal_reload()
            setCarReloadModalStatus('')
          }, 2000)
        } else {
          setCarReloadModalStatus('error')
          setTimeout(function () {
            setCarReloadModalStatus('')
          }, 2000)
        }
      }
    } else {
      setCarReloadModalStatus('error')
      setTimeout(function () {
        setCarReloadModalStatus('')
      }, 2000)
    }
  }

  const shareRoute = async () => {
    toggleModalRoute(true)
    setCarRouteLoading(true)
    let carRouteResponse = await axios_api.get_car_route_key(car.id, cookies.csrftoken)
    setCarRouteKey(carRouteResponse.data.key)
    setCarRouteLoading(false)
  }

  const toggleModalRoute = (open) => {
    if (open) {
      let notifications = null
      if (document.getElementsByClassName('main_div_notifications').length) {
        notifications = document.getElementsByClassName('main_div_notifications')[0]
      }
      if (notifications) {
        notifications.style.display = 'none'
      }
      setCarRouteModal(true)
    } else {
      let notifications = null
      if (document.getElementsByClassName('main_div_notifications').length) {
        notifications = document.getElementsByClassName('main_div_notifications')[0]
      }
      if (notifications) {
        notifications.style.display = 'block'
      }
      setCarRouteModal(false)
    }
  }

  const close_modal_reload = () => {
    let notifications = null
    if (document.getElementsByClassName('main_div_notifications').length) {
      notifications = document.getElementsByClassName('main_div_notifications')[0]
    }
    if (notifications) {
      notifications.style.display = 'block'
    }
    setCarReloadModal(false)
  }

  const open_modal_reload = () => {
    let notifications = null
    if (document.getElementsByClassName('main_div_notifications').length) {
      notifications = document.getElementsByClassName('main_div_notifications')[0]
    }
    if (notifications) {
      notifications.style.display = 'none'
    }
    setCarReloadModal(true)
  }

  let command_engine = null

  let engine_icon
  let engine_text

  if (car.commands) {
    for (let command of car.commands) {
      if (command.type == 'ENGINE') {
        command_engine = command
        if (command.pending) {
          engine_icon = (
            <img
              src={command.state ? lock_close_icon_wait : lock_open_icon_wait}
              data_command_type={'cancel_command'}
              data_command_id={command.id}
              data_id={car.id}
              data_number={car.car_number}
              onClick={(e) => send_command_to_car(e)}
            />
          )
          engine_text = 'Отменить выполнение команды'
        } else {
          if (command.state) {
            engine_icon = (
              <img
                src={lock_close_icon}
                data_command_type={'turn_on_engine'}
                data_command_id={command.id}
                data_id={car.id}
                data_number={car.car_number}
                onClick={(e) => send_command_to_car(e)}
              />
            )
            engine_text = 'Разблокировать двигатель'
          } else {
            engine_icon = (
              <img
                src={lock_open_icon}
                data_command_type={'turn_off_engine'}
                data_command_id={command.id}
                data_id={car.id}
                data_number={car.car_number}
                onClick={(e) => send_command_to_car(e)}
              />
            )
            engine_text = 'Заглушить двигатель'
          }
        }
      }
    }
  } else {
    console.log('у маяка нет команд')
  }

  let velocity = 0
  let last_dt_online = ''

  if (car.locations) {
    if (car.locations[0]) {
      velocity = car.locations[0].velocity

      let time = car.locations[0].dt.split('T')[1]
      time = time.split(':')[0] + ':' + time.split(':')[1]
      let date = car.locations[0].dt.split('T')[0]
      date = date.split('-')[2] + '.' + date.split('-')[1] + '.' + date.split('-')[0]
      last_dt_online = ' с ' + time + ' ' + date
    }
  }

  return (
    <div className='table_routes_row' onClick={focus_map}>
      <div className='table_routes_div'>
        <div className='table_routes_td_img'>
          {status_icons.online ? (
            <div style={{ position: 'relative' }}>
              <img src={car_data.get_svg_car(car.color)} style={{ height: '20px' }} />
            </div>
          ) : (
            <div style={{ position: 'relative' }}>
              <img src={car_data.get_svg_car(car.color)} style={{ height: '20px' }} />
              <img
                src={warning_red_icon}
                style={{ position: 'absolute', left: '0', top: '-3px' }}
              />
            </div>
          )}
        </div>
        <div className='table_routes_td_number'>
          {status_icons.online ? (
            <>
              {velocity != 0 ? (
                <Tippy
                  render={(attrs) => (
                    <div className='box tippy_content' tabIndex='-1' {...attrs}>
                      Скорость: {velocity} км/ч
                    </div>
                  )}
                >
                  <div className='table_routes_number'>{car.car_number}</div>
                </Tippy>
              ) : (
                <Tippy
                  render={(attrs) => (
                    <div className='box tippy_content' tabIndex='-1' {...attrs}>
                      Скорость: {velocity} км/ч
                    </div>
                  )}
                >
                  <div className='table_routes_number stop'>{car.car_number}</div>
                </Tippy>
              )}
            </>
          ) : (
            <div className='table_routes_number ofline'>{car.car_number}</div>
          )}
          <div className='table_routes_brand'>
            {car.brand} {car.model}
          </div>
        </div>
        <div className='' style={{ marginLeft: '5px' }}>
          {status_icons.online ? (
            <Tippy
              render={(attrs) => (
                <div className='box tippy_content' tabIndex='-1' {...attrs}>
                  Трекер в сети
                </div>
              )}
            >
              <img src={power_on_icon} />
            </Tippy>
          ) : (
            <Tippy
              render={(attrs) => (
                <div className='box tippy_content' tabIndex='-1' {...attrs}>
                  Трекер не в сети {last_dt_online}
                </div>
              )}
            >
              <img src={power_off_icon} />
            </Tippy>
          )}
        </div>
        <div className='' style={{ marginLeft: '15px' }}>
          {status_icons.insurance !== null ? (
            status_icons.insurance ? (
              <Tippy
                render={(attrs) => (
                  <div className='box tippy_content' tabIndex='-1' {...attrs}>
                    Страховка активна
                  </div>
                )}
              >
                <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}>
                  <img src={insurance_green} />
                </div>
              </Tippy>
            ) : status_icons.insurance_locked ? (
              <Tippy
                render={(attrs) => (
                  <div className='box tippy_content' tabIndex='-1' {...attrs}>
                    Автомобиль заблокирован из-за отсутствия страховки у водителя
                  </div>
                )}
              >
                <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}>
                  <img src={insurance_red} />
                </div>
              </Tippy>
            ) : (
              <Tippy
                render={(attrs) => (
                  <div className='box tippy_content' tabIndex='-1' {...attrs}>
                    Страховка отсутствует
                  </div>
                )}
              >
                <div style={{ display: 'flex', justifyContent: 'center', width: '42px' }}>
                  <img src={insurance_warn} />
                </div>
              </Tippy>
            )
          ) : status_icons.power ? (
            <Tippy
              render={(attrs) => (
                <div className='box tippy_content' tabIndex='-1' {...attrs}>
                  Трекер подключен к питанию
                </div>
              )}
            >
              <div style={{ position: 'relative' }}>
                <img src={battery_level_border_on} />
                <div className='text_battery_level'>{status_icons.battery}</div>
              </div>
            </Tippy>
          ) : (
            <Tippy
              render={(attrs) => (
                <div className='box tippy_content' tabIndex='-1' {...attrs}>
                  Трекер не подключен к питанию
                </div>
              )}
            >
              <div style={{ position: 'relative' }}>
                <img src={battery_level_border_off} />
                <div className='text_battery_level'>{status_icons.battery}</div>
              </div>
            </Tippy>
          )}
        </div>
        <div className='' style={{ marginLeft: '15px' }}>
          {command_engine ? (
            <Tippy
              render={(attrs) => (
                <div className='box tippy_content' tabIndex='-1' {...attrs}>
                  {engine_text}
                </div>
              )}
            >
              {engine_icon}
            </Tippy>
          ) : (
            <Tippy
              render={(attrs) => (
                <div className='box tippy_content' tabIndex='-1' {...attrs}>
                  Команда глушения двигателя не доступна для этого авто
                </div>
              )}
            >
              <img src={car_engine_null} />
            </Tippy>
          )}
        </div>
        <div className='table_routes_td_options'>
          <Option_btn_scroll>
            <div className='item_option_btn_scroll' onClick={show_route}>
              История поездок
            </div>
            <Link to={'/car_list?car=' + props.car.id} style={{ textDecoration: 'none' }}>
              <div className='item_option_btn_scroll'>Перейти в профиль</div>
            </Link>
            <div className='item_option_btn_scroll' onClick={reloadCar}>
              Перезагрузить трекер
            </div>
            {last_dt_online !== '' ? (
              <div className='item_option_btn_scroll' onClick={open_yandex_location}>
                Поделиться местоположением
              </div>
            ) : (
              <></>
            )}
            <div className='item_option_btn_scroll' onClick={shareRoute}>
              Поделиться маршрутом
            </div>
          </Option_btn_scroll>
        </div>
      </div>
      <div className='div_border_bottom_table_routes_row'>
        <div className='border_bottom_table_routes_row'></div>
      </div>
      <Modal_with_accept active={carReloadModal} setActive={close_modal_reload}>
        <div className='text_accept_delete'>
          Вы уверены, что хотите перезагрузить трекер в авто "{car.car_number}"?
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
          <Btn_defolt
            color={'#EFF3FF'}
            text_color={'#6486FB'}
            hover_color={'#dbe4ff'}
            text={'Отмена'}
            height={'40px'}
            width={'150px'}
            onClick={close_modal_reload}
          />
          <Btn_defolt
            color={'#6486FB'}
            text_color={'#FFFFFF'}
            hover_color={'#5179fc'}
            text={'Перезагрузить'}
            height={'40px'}
            width={'150px'}
            onClick={acceptReloadCar}
          />
        </div>
        <div
          className={
            carReloadModalStatus == 'success'
              ? 'status_modal_success active'
              : 'status_modal_success'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Успешно!</div>
            <div className='text_status_info'>
              Команда перезагрузки "{car.car_number}" отправлена!
            </div>
          </div>
        </div>
        <div
          className={
            carReloadModalStatus == 'error' ? 'status_modal_error active' : 'status_modal_error'
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='text_status_main'>Ошибка!</div>
          </div>
        </div>
        <div
          className={
            carReloadModalStatus == 'loading'
              ? 'status_modal_loading active'
              : 'status_modal_loading'
          }
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              className='text_accept_delete'
              style={{ marginTop: '16px', width: '360px', textAlign: 'center' }}
            >
              Команда в очереди на отправку.
            </div>
            <div className='text_accept_delete' style={{ width: '340px' }}>
              Это может занять несколько минут.
            </div>
            <ReactLoading type={'spin'} color='#6486FB' />
          </div>
        </div>
      </Modal_with_accept>
      <CarRouteKeyModal
        active={carRouteModal}
        setActive={toggleModalRoute}
        routeKey={carRouteKey}
        carNumber={car.car_number}
        loading={carRouteLoading}
      />
    </div>
  )

  async function focus_map() {
    //console.log('car_id : ', car.id)
    await map_api.zoom_on_car(map_object, car.id)
  }
}
