import React, { useEffect, useState, useContext } from 'react'
import '../../../styles/routes_map/car_table_zone.css'

import Table_item from './car_table_new_zone_item'
import car_data from '../../../API/car_data'
export default function Car_table_new_zone(props) {
  //console.log('props : ', props)

  let searched_array = []

  const [render_table, setRender_table] = useState(false)

  props.cars.map((car) => {
    const isCarInGroup = car.car_group.includes(Number(props.group_sort_id))
    if (!props.search) {
      if (props.group_sort_id == 'ALL' || isCarInGroup) {
        searched_array.push(car)
      }
    } else {
      if (
        car.car_number &&
        car.car_number.toUpperCase().includes(props.search.toUpperCase()) &&
        (props.group_sort_id == 'ALL' || isCarInGroup)
      ) {
        searched_array.push(car)
      }
    }
  })

  function check_checked(car_id) {
    if (props.add_cars_new_zone.includes(car_id)) {
      return 'checked'
    } else {
      return 'unchecked'
    }
  }

  return (
    <div>
      {searched_array.length ? (
        <>
          {searched_array.map((car) => (
            <Table_item
              car_img={car_data.get_svg_car(car.color)}
              car={car}
              checked={check_checked(car.id)}
              key={car.id}
              add_cars_new_zone={props.add_cars_new_zone}
              setAdd_cars_new_zone={props.setAdd_cars_new_zone}
              setRender_table={setRender_table}
            />
          ))}
        </>
      ) : (
        <div className='no_cars'>Нет авто</div>
      )}
    </div>
  )
}
