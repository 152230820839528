import axios from 'axios'

let base_url, login_url

if (process.env.REACT_APP_SERVER == 'PROD') {
  login_url = 'https://api.controlgps.org/api/v2/auth'
  base_url = 'https://api.controlgps.org/api/v2/'
} else {
  login_url = 'http://dev.api.controlgps.org/api/v2/auth'
  base_url = 'http://dev.api.controlgps.org/api/v2/'
}

export default class axios_api {
  static async get_markers(token, frame_index) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
      'X-CSRFToken': token,
    }

    let href = base_url + 'locations/current?frame_index=' + frame_index

    //console.log('axios get_markers')
    let response = await axios.get(href, { headers })
    //console.log('response get_markers : ', response)
    return response.data
  }

  static async get_routes(car_id, start_end, token, reqId) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let date_start = start_end.start
    let date_end = start_end.end

    let href =
      base_url +
      'locations/route?car_id=' +
      car_id +
      '&date_start=' +
      date_start +
      '&date_end=' +
      date_end

    let response = await axios.get(href, { headers }).catch(function (resp) {
      return {
        data: resp.response.data,
        status: resp.response.status,
      }
    })

    response.reqId = reqId

    console.log('response get_routes : ', response)

    return response
  }

  static async get_car_params(id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + `cars/${id}/inspections/`, {
      headers,
    })
    //console.log('response : ', response)
    return response.data
  }

  static async carParamAccept(id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + `inspections/${id}/passed/`, {
      headers,
    })
    //console.log('response : ', response)
    return response
  }

  static async get_inspections_groups(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'inspection-groups/', { headers })
    //console.log('response : ', response)
    return response.data
  }

  static async get_inspection_params(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'inspection-categories/', {
      headers,
    })
    console.log('response get_inspection_params : ', response)
    return response.data
  }

  static async get_all_metrics_resp(cars, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/metrics/'

    let parametrs = '?'

    for (let car of cars) {
      parametrs = parametrs + 'car_id=' + car + '&'
    }

    url = url + parametrs
    url = url.slice(0, -1)

    let response = await axios.get(url, { headers })
    //console.log('response : ', response)
    return response
  }

  static async get_cars_brands_and_models(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'inspection-groups/brands-models-list/', { headers })
    //console.log('response : ', response)
    return response.data
  }

  static async get_groups(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'groups/', { headers })
    //console.log('response : ', response)
    return response.data
  }

  static async post_new_group(full_name, short_name, cars, token) {
    let body = {
      name: full_name,
      short_name: short_name,
      cars: cars,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.post(base_url + 'groups/', body, {
      headers: headers,
    })

    //console.log('response : ', response)

    return response
  }

  static async postMobileCar(carNumber, fio, lifetime, token) {
    let body = {
      car_number: carNumber,
      fio: fio,
      cars: lifetime,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.patch(base_url + 'cars/new_mobile/', body, {
      headers: headers,
    })

    //console.log('response : ', response)

    return response
  }

  static async saveCarInspection(inspectionId, carId, token) {
    let body = {
      inspection_group: inspectionId,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.patch(base_url + `cars/${carId}/`, body, {
      headers: headers,
    })

    console.log('response saveCarInspection: ', response)

    return response
  }

  static async saveInspection(inspectionId, nameValue, brandValue, modelValue, paramsIds, token) {
    let body = {
      model: modelValue,
      brand: brandValue,
      verbose_name: nameValue,
      categories: paramsIds,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.patch(base_url + `inspection-groups/${inspectionId}/`, body, {
      headers: headers,
    })

    console.log('response saveInspection: ', response)

    return response
  }

  static async postInspection(nameValue, brandValue, modelValue, paramsIds, token) {
    let body = {
      model: modelValue,
      brand: brandValue,
      verbose_name: nameValue,
      categories: paramsIds,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.post(base_url + `inspection-groups/`, body, {
      headers: headers,
    })

    console.log('response postInspection: ', response)

    return response
  }

  static async deleteInspection(id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.delete(base_url + `inspection-groups/${id}/`, {
      headers,
    })

    console.log('response deleteInspection: ', response)

    return response
  }

  static async saveInspectionParam(
    inspectionId,
    paramId,
    verbose_name,
    period_type,
    value_period,
    mileage_ranges,
    token,
  ) {
    let body = {
      inspection_group: inspectionId,
      verbose_name: verbose_name,
      period_type: period_type,
      value_period: value_period,
      mileage_ranges: mileage_ranges,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.patch(base_url + `inspection-categories/${paramId}/`, body, {
      headers: headers,
    })

    console.log('response saveInspectionParam: ', response)

    return response
  }

  static async postInspectionParam(
    inspectionId,
    verbose_name,
    period_type,
    value_period,
    mileage_ranges,
    token,
  ) {
    let body = {
      inspection_group: inspectionId,
      verbose_name: verbose_name,
      period_type: period_type,
      value_period: value_period,
      mileage_ranges: mileage_ranges,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.post(base_url + `inspection-categories/`, body, {
      headers: headers,
    })

    console.log('response postInspectionParam: ', response)

    return response
  }

  static async deleteInspectionParam(paramId, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.delete(base_url + `inspection-categories/${paramId}/`, { headers })

    console.log('response deleteInspectionParam: ', response)

    return response
  }

  static async edit_group(id, full_name, short_name, cars, token) {
    let body = {
      name: full_name,
      short_name: short_name,
      cars: cars,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'groups/' + id + '/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    //console.log('response edit_group : ', response)

    return response
  }

  static async geozone_settings_edit(geozone_engine_stop_safe, geozone_phone_number, token) {
    let body = {
      geozone_engine_stop_safe: geozone_engine_stop_safe,
      geozone_phone_number: geozone_phone_number,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'settings/current/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    return response
  }

  static async insurance_settings_edit(
    insurance_monitoring,
    insurance_engine_block,
    insurance_notify,
    token,
  ) {
    let body = {
      insurance_monitoring: insurance_monitoring,
      insurance_engine_block: insurance_engine_block,
      insurance_notify: insurance_notify,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'settings/current/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    return response
  }

  static async driving_quality_edit(
    driving_accuracy_monitoring,
    DAM_speed_limit,
    DAM_angle_speed_limit,
    DAM_acceleration_limit,
    token,
  ) {
    let body = {
      driving_accuracy_monitoring: driving_accuracy_monitoring,
      DAM_speed_limit: DAM_speed_limit,
      DAM_angle_speed_limit: DAM_angle_speed_limit,
      DAM_acceleration_limit: DAM_acceleration_limit,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'settings/current/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    //console.log('response edit_group : ', response)

    return response
  }

  static async park_geolocation_edit(map_lat, map_lon, zoom, token) {
    let body = {
      map_default_lat: map_lat,
      map_default_lon: map_lon,
      map_default_zoom: zoom,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'settings/current/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    return response
  }

  static async get_geozones(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'geozones/', { headers })
    //console.log('response : ', response)
    console.log('response get_geozones: ', response.data)
    return response.data
  }

  static async post_new_geozone(name, points, cars, token) {
    let body = {
      name: name,
      points_json: points,
      cars: cars,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.post(base_url + 'geozones/', body, {
      headers: headers,
    })

    //console.log('response : ', response)

    return response
  }

  static async patch_coord_geozone(zone_id, name, points, token) {
    let body = {
      name: name,
      points_json: points,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'geozones/' + zone_id + '/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    console.log('response edit_geozone : ', response)

    return response
  }

  static async delete_geozone(id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'geozones/' + id + '/'

    let response = await axios.delete(url, { headers })

    console.log('response delete geozone: ', response)

    return response
  }

  static async delete_group(id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'groups/' + id + '/'

    let response = await axios.delete(url, { headers })

    console.log('response delete group: ', response)

    return response
  }

  static async delete_user(id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'users/' + id + '/'

    let response = await axios.delete(url, { headers })

    console.log('response delete user: ', response)

    return response
  }

  static async remove_car_from_geozone(zone_id, car_id, token) {
    let body = {
      id: car_id,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'geozones/' + zone_id + '/car/'

    let response = await axios.delete(url, {
      headers: headers,
      data: body,
    })

    console.log('response remove_car_from_geozone: ', response)

    return response
  }

  static async add_car_to_geozone(zone_id, car_id, token) {
    let body = {
      id: car_id,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'geozones/' + zone_id + '/car/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    console.log('response add_car_geozone : ', response)

    return response
  }

  static async add_cars_geozone(zone_id, cars, token) {
    let body = {
      cars: cars,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'geozones/' + zone_id + '/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    console.log('response add_cars_geozone : ', response)

    return response
  }

  static async get_car_route_key(id, token) {
    let body = {
      car_id: id,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.patch(base_url + 'sessions/new/', body, {
      headers: headers,
    })

    console.log('response get_car_route_key : ', response)

    return response
  }

  static async get_cars(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
      'X-CSRFToken': token,
    }

    let response = await axios.get(base_url + 'cars/', { headers })
    //console.log('response : ', response)
    return response.data
  }

  static async get_cars_detailed(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'cars/detailed/', { headers })
    // console.log('response get_cars_detailed: ', response.data)
    //console.log('response : ', response)
    return response.data
  }

  static async get_cars_activity(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'cars/active/', { headers })
    //console.log('response : ', response)
    return response.data
  }

  static async get_cars_top_mil(mode, group_sort_id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    if (mode == 'DAY') {
      mode = 'day'
    }
    if (mode == 'MONTH') {
      mode = 'month'
    }
    if (mode == 'WEEK') {
      mode = 'week'
    }

    let url

    if (group_sort_id == 'ALL') {
      url = base_url + 'dashboard/mileage/?mode=' + mode
    } else {
      url = base_url + '/dashboard/mileage/?mode=' + mode + '&car_group=' + group_sort_id
    }

    let response = await axios.get(url, { headers })

    return response.data
  }

  static async get_cars_top_mil(mode, group_sort_id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    if (mode == 'DAY') {
      mode = 'day'
    }
    if (mode == 'MONTH') {
      mode = 'month'
    }
    if (mode == 'WEEK') {
      mode = 'week'
    }

    let url

    if (group_sort_id == 'ALL') {
      url = base_url + 'dashboard/mileage?mode=' + mode
    } else {
      url = base_url + 'dashboard/mileage?mode=' + mode + '&car_group=' + group_sort_id
    }

    let response = await axios.get(url, { headers })

    return response.data
  }

  static async get_cars_payment_history(group_sort_id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let url

    if (group_sort_id == 'ALL') {
      url = base_url + 'dashboard/payments'
    } else {
      url = base_url + 'dashboard/payments?car_group=' + group_sort_id
    }

    let response = await axios.get(url, { headers })

    return response.data
  }

  static async get_cars_driving_issues(mode, group_sort_id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    if (mode == 'DAY') {
      mode = 'day'
    }
    if (mode == 'MONTH') {
      mode = 'month'
    }
    if (mode == 'WEEK') {
      mode = 'week'
    }

    let url

    if (group_sort_id == 'ALL') {
      url = base_url + 'driving_issues?mode=' + mode
    } else {
      url = base_url + 'driving_issues?mode=' + mode + '&car_group=' + group_sort_id
    }

    let response = await axios.get(url, { headers })

    return response.data
  }

  static async get_car_driving_issues_detail(car_id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'driving_issues/' + car_id

    let response = await axios.get(url, { headers })

    return response.data
  }

  static async get_cars_activity_history(token, mode, group_sort_id) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    if (mode == 'DAY') {
      mode = 'day'
    }
    if (mode == 'MONTH') {
      mode = 'month'
    }
    if (mode == 'WEEK') {
      mode = 'week'
    }

    let url

    if (group_sort_id == 'ALL') {
      url = base_url + 'cars/activity_history/?mode=' + mode
    } else {
      url = base_url + 'cars/activity_history/?mode=' + mode + '&car_group=' + group_sort_id
    }

    let response = await axios.get(url, { headers })
    return response.data
  }

  static async car_history(token, mode, metric, car_id) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    if (mode == 'DAY') {
      mode = 'day'
    }
    if (mode == 'MONTH') {
      mode = 'month'
    }
    if (mode == 'WEEK') {
      mode = 'week'
    }

    let url = base_url + 'cars/' + car_id + '/history/?mode=' + mode + '&metric=' + metric

    let resp = ''

    await axios
      .get(url, { headers })
      .then(function (response) {
        // console.log('response : ', response)
        resp = response.data
      })
      .catch(function (response) {
        // console.log('error')
        resp = 'error'
      })

    return resp
  }

  static async reload_car(token, car_id) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/send_reboot/'

    let resp = ''

    await axios
      .get(url, { headers })
      .then(function (response) {
        // console.log('response : ', response)
        resp = response.data
      })
      .catch(function (response) {
        // console.log('error')
        resp = 'error'
      })

    return resp
  }

  static async get_status_reload_car(token, car_id) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/sms_status/'

    let resp = ''

    await axios
      .get(url, { headers })
      .then(function (response) {
        // console.log('response : ', response)
        resp = response.data
      })
      .catch(function (response) {
        // console.log('error')
        resp = 'error'
      })

    return resp
  }

  static async get_users(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'users/', { headers })
    //console.log('response : ', response)
    return response.data
  }

  static async edit_user(
    id,
    last_name,
    first_name,
    middle_name,
    mail,
    car_groups,
    permissions,
    icons_size,
    token,
  ) {
    let body = {
      last_name: last_name,
      first_name: first_name,
      middle_name: middle_name,
      email: mail,
      car_groups: car_groups,
      permissions: permissions,
      icons_size: icons_size.toFixed(1),
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'users/' + id + '/'

    // console.log(body)

    let response = {
      status: '200',
    }

    await axios
      .patch(url, body, {
        headers: headers,
      })
      .catch(function (resp) {
        if (resp.response.status != '200') {
          response.status = 'error'
        } else {
          response.status = '200'
        }
      })

    console.log('response edit_group : ', response)

    return response
  }

  static async edit_password_user(id, password, token) {
    let body = {
      password: password,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'users/' + id + '/password/'

    // console.log(body)

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    //console.log('response edit_group : ', response)

    return response
  }

  static async post_new_user(
    last_name,
    first_name,
    middle_name,
    mail,
    password,
    car_groups,
    permissions,
    token,
  ) {
    let body = {
      last_name: last_name,
      first_name: first_name,
      middle_name: middle_name,
      email: mail,
      password: password,
      car_groups: car_groups,
      permissions: permissions,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'users/new/'

    let status = '201'

    let response = await axios
      .patch(url, body, {
        headers: headers,
      })
      .catch(function (resp) {
        status = resp.response.status
      })

    return status
  }

  static async post_new_aggregator(name, yandex_api_key, yandex_client_id, yandex_park_id, token) {
    let body = {
      name: name,
      yandex_api_key: yandex_api_key,
      yandex_client_id: yandex_client_id,
      yandex_park_id: yandex_park_id,
      type: 'YAN',
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'aggregator/'

    let status = 'success'

    let response = await axios
      .post(url, body, {
        headers: headers,
      })
      .catch(function (resp) {
        if (resp.response.status == '400') {
          status = 'error'
        }
      })

    return status
  }

  static async delete_aggregator(id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'aggregator/' + id + '/'

    let response = await axios.delete(url, { headers })

    console.log('response delete aggregator: ', response)

    return response
  }

  static async get_user_info(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'users/current/', { headers })
    // console.log('response get_user_info: ', response)
    return response.data
  }

  static async get_settings_current(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'settings/current/', { headers })
    //console.log('response get_payment_info: ', response)

    return response.data
  }

  static async get_bill(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let status = base_url + 'partners/invoice/'

    let response = await axios
      .get(base_url + 'partners/invoice/', { headers })
      .catch(function (resp) {
        if (resp.response.status == 404) {
          // console.log('resp ', resp)
          status = 'error'
        } else {
        }
      })

    return status
  }

  static async get_invoices(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'invoices/', { headers })

    return response.data
  }

  static async get_payments(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'payments/', { headers })

    return response.data
  }

  static async pay_bill_url(id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'payments/' + id + '/init/'

    let response = await axios.get(url, { headers })

    return response.data.url
  }

  static async get_bills(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let response = await axios.get(base_url + 'invoices/', { headers })

    return response.data
  }

  static async save_edit_payment(
    qiwi,
    inactive_top_up,
    periodic_top_up,
    value_inactive_top_up,
    days_inactive_top_up,
    value_periodic_top_up,
    token,
  ) {
    // {
    //     "qiwi_api_key": "string",
    //     "periodic_top_up": true,
    //     "periodic_top_up_value": 2147483647,
    //     "inactive_top_up": true,
    //     "inactive_top_up_period": 2147483647,
    //     "inactive_top_up_value": 2147483647,
    //     "is_active": true,
    //     "partner": 0
    // }

    let body = {
      qiwi_api_key: qiwi,
      periodic_top_up: periodic_top_up,
      periodic_top_up_value: value_periodic_top_up,
      inactive_top_up: inactive_top_up,
      inactive_top_up_period: days_inactive_top_up,
      inactive_top_up_value: value_inactive_top_up,
    }

    //console.log('body : ', body)

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'settings/current/'

    let status

    let response = await axios
      .patch(url, body, {
        headers: headers,
      })
      .then(function (response) {
        if (response.status == '200') {
          status = '200'
        } else {
          status = 'error'
        }
      })
      .catch(function (response) {
        status = 'error'
      })
    console.log('response payment: ', response)

    return status
  }

  static async send_command_to_car(command_id, car_id, token) {
    console.log('send_command : ', command_id, ' car : ', car_id)

    let body = {
      id: command_id,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/command/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    console.log('response send_command_to_car : ', response)

    return response
  }

  static async cancel_command_to_car(command_id, car_id, token) {
    console.log('cancel_command : ', command_id, ' car : ', car_id)

    let body = {
      id: command_id,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/cancel_command/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })

    console.log('response cancel_command_to_car : ', response)

    return response
  }

  static async car_color(color_name, car_id, token) {
    let body = {
      color: color_name,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })
    console.log('response_car_color : ', response)
    return response
  }

  static async car_aggregator(aggregator, car_id, token) {
    let body = {
      aggregator: aggregator,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/'

    // let response = await axios.patch(url, body, {
    //     headers: headers,
    // })
    // console.log('response_car_aggregator : ', response)
    // return response

    let status = 'success'

    let response = await axios
      .patch(url, body, {
        headers: headers,
      })
      .catch(function (resp) {
        if (resp.response.status == 404) {
          console.log('resp ', resp)
          status = 'error'
        } else {
        }
      })

    console.log('response_car_aggregator : ', response)

    return status
  }

  static async car_brand(brand, car_id, token) {
    let body = {
      brand: brand,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })
    console.log('response_car_brand : ', response)
    return response
  }

  static async car_model(model, car_id, token) {
    let body = {
      model: model,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })
    console.log('response_car_brand : ', response)
    return response
  }

  static async car_mil(mileage, car_id, token) {
    let body = {
      mileage: mileage,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })
    console.log('response_car_mileage : ', response)
    return response
  }

  static async driver_block_engine(
    engine_block_active,
    engine_block_balance_threshold,
    driver_id,
    token,
  ) {
    let body = {
      engine_block_active: engine_block_active,
      engine_block_balance_threshold: engine_block_balance_threshold,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'drivers/' + driver_id + '/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })
    console.log('response driver_block_engine : ', response)
    return response
  }

  static async car_number(car_number, car_id, token) {
    let body = {
      car_number: car_number,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })
    console.log('response_car_number : ', response)
    return response
  }

  static async car_geozone_block(geozone_engine_block, car_id, token) {
    let body = {
      geozone_engine_block: geozone_engine_block,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/'

    let response = await axios.patch(url, body, {
      headers: headers,
    })
    console.log('response_geozone_engine_block : ', response)
    return response
  }

  static async car_unblock_until(unblock_until, car_id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/' + car_id + '/unblock_until/?date=' + unblock_until

    let response = await axios.get(url, {
      headers: headers,
    })
    console.log('response_car_unblock_until : ', response)
    return response
  }

  static async get_notifications(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'notifications/'

    let response = await axios.get(url, { headers })

    console.log('response get_notifications: ', response)

    response.data.sort(function (a, b) {
      return new Date(b.datetime) - new Date(a.datetime)
    })

    return response.data
  }

  static async get_drivers(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'drivers/'

    let response = await axios.get(url, { headers })

    // console.log('response get_drivers: ', response)

    return response.data
  }

  static async get_drivers_active(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'drivers/active/'

    let response = await axios.get(url, { headers })

    // console.log('response get_drivers: ', response)

    return response.data
  }

  static async get_aggregators(token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'aggregator/'

    let response = await axios.get(url, { headers })

    // console.log('response aggregators: ', response)

    return response.data
  }

  static async hide_notification(id, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'notifications/' + id + '/hide/'

    let response = await axios.get(url, { headers })

    console.log('response hide_notification: ', response)

    return response
  }

  static async get_report(cars, metrics, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let params = {
      car_id: cars,
      metric: metrics,
    }

    let url = base_url + 'cars/export/'

    let parametrs = '?'

    for (let car of cars) {
      parametrs = parametrs + 'car_id=' + car + '&'
    }
    for (let metric of metrics) {
      parametrs = parametrs + 'metric=' + metric + '&'
    }
    url = url + parametrs
    url = url.slice(0, -1)

    console.log('url: ', url)

    let response = await axios.get(url, {
      headers: headers,
      responseType: 'blob',
    })

    console.log('response get_report: ', response)

    const href = URL.createObjectURL(response.data)
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', 'report.xlsx')
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  // static async get_bill_url(id, token){

  //     const headers = {
  //         'Access-Control-Allow-Origin': '*',
  //         'Content-Type': 'application/json',
  //         'accept': 'application/json',
  //         'X-CSRFToken': token,
  //         'Authorization': 'Token ' + token
  //     }

  //     let url = base_url + 'invoices/' + id + '/url/'

  //     let response = await axios.get(url, { headers })

  //     console.log('response get_bill_url: ', response)

  //     return response.data

  // }

  static async download_bill(bill_url, date, token) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    bill_url = bill_url.substring(1)

    let url = base_url + bill_url

    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    // let response = await axios.get(url, {headers: headers, responseType: 'blob'})

    // console.log('response download_bill: ', response)

    // const href = URL.createObjectURL(response.data);
    // const link = document.createElement('a');
    // link.href = href;
    // let name = 'bill_' + date + '.pdf'
    // link.setAttribute('download', name);
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // URL.revokeObjectURL(href);
  }

  static async get_stats_chart(cars, mode, metric, token) {
    // console.log('cars : ', cars)
    // console.log('mode : ', mode)
    // console.log('metric : ', metric)

    if (mode == 'DAY') {
      mode = 'day'
    }
    if (mode == 'MONTH') {
      mode = 'month'
    }
    if (mode == 'WEEK') {
      mode = 'week'
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    let url = base_url + 'cars/stats/'

    let parametrs = '?'

    for (let car of cars) {
      parametrs = parametrs + 'car_id=' + car + '&'
    }

    parametrs = parametrs + 'metric=' + metric + '&mode=' + mode

    url = url + parametrs

    let response = await axios.get(url, { headers: headers })

    // console.log('response get_stats_chart: ', response)

    return response
  }

  static async addLicense(type, amount, token) {
    let body = {
      amount: amount,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: 'application/json',
      'X-CSRFToken': token,
      Authorization: 'Token ' + token,
    }

    if (type === 'invoice') {
      let response = await axios.patch(base_url + `partners/add_license_invoice/`, body, {
        headers: headers,
      })

      console.log('response add_license_invoice: ', response)

      return response
    }

    if (type === 'payment') {
      let response = await axios.patch(base_url + `partners/add_license_payment/`, body, {
        headers: headers,
      })

      console.log('response add_license_payment: ', response)

      return response
    }
  }

  static async post_clients(name, phone, token) {
    let body = {
      name: name,
      phone: phone,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }

    let response = await axios.post(base_url + 'clients/', body, {
      headers: headers,
    })

    console.log('response post_clients : ', response)

    return response
  }

  static async login(email, password) {
    let body = {
      email: email,
      password: password,
    }

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }

    let status = ''

    let response = await axios
      .post(login_url, body, {
        headers: headers,
      })
      .then(function (response) {
        console.log('response ajax_auth: ', response)
        if (response.data.token) {
          status = response.data.token
        } else {
          status = 'error'
        }
      })
      .catch(function (response) {
        status = 'error'
      })

    return status
  }

  static async license_agreement() {
    let url = base_url + 'legal/license'
    window.open(url)
  }
}
