import React, { useEffect, useState, useContext } from 'react'
import '../../../styles/park_settings/main_park_settings.css'
import Switch from 'react-switch'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import Btn_defolt from '../../UI/btn_defolt.jsx'
import Fake_btn_defolt from '../../UI/fake_btn_defolt.jsx'

import axios_api from '../../../API/axios_api.js'
import { useCookies } from 'react-cookie'

export default function Insurance(props) {
  const [cookies] = useCookies(['csrftoken'])

  const [insurance_monitoring, setInsurance_monitoring] = useState(false)
  const [insurance_engine_block, setInsurance_engine_block] = useState(false)
  const [insurance_notify, setInsurance_notify] = useState(false)

  const [status_save_edit_btn, setStatus_save_edit_btn] = useState(null)

  useEffect(() => {
    if (props.settings_current) {
      setInsurance_monitoring(props.settings_current.insurance_monitoring || false)
      setInsurance_engine_block(props.settings_current.insurance_engine_block || false)
      setInsurance_notify(props.settings_current.insurance_notify || false)
    }
  }, [])

  async function save_edit() {
    let response_edit = await axios_api.insurance_settings_edit(
      insurance_monitoring,
      insurance_engine_block,
      insurance_notify,
      cookies.csrftoken,
    )

    if (response_edit.status == '200') {
      setStatus_save_edit_btn('success')
    } else {
      setStatus_save_edit_btn('error')
    }

    setTimeout(function () {
      setStatus_save_edit_btn(null)
    }, 2000)

    props.update_all()
  }

  return (
    <div className='div_main_setting'>
      <div className='header_refill_settings'>
        <div className='text_header_refill_settings'>Контроль страховок водителей</div>
      </div>
      <div style={{ marginTop: '30px', marginLeft: '16px' }}>
        <div
          style={{ marginTop: '30px', display: 'flex', alignItems: 'center', marginBottom: '20px' }}
        >
          <Switch
            onChange={(e) => setInsurance_monitoring(!insurance_monitoring)}
            checked={insurance_monitoring}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={36}
            offColor={'#E7ECFB'}
            onColor={'#6486FB'}
            activeBoxShadow={'0 0 2px 3px #6486FB'}
          />
          <div
            className={
              insurance_monitoring ? 'text_checkbox_with_text active' : 'text_checkbox_with_text'
            }
            style={{ marginLeft: '10px' }}
          >
            Включить контроль страховок водителей
          </div>
        </div>
        <div
          style={{ marginTop: '30px', display: 'flex', alignItems: 'center', marginBottom: '20px' }}
        >
          <SlideDown className={'my-dropdown-slidedown'}>
            {insurance_monitoring && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <Switch
                    onChange={(e) => setInsurance_notify(!insurance_notify)}
                    checked={insurance_notify}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={36}
                    offColor={'#E7ECFB'}
                    onColor={'#6486FB'}
                    activeBoxShadow={'0 0 2px 3px #6486FB'}
                  />
                  <div
                    className={
                      insurance_notify
                        ? 'text_checkbox_with_text active'
                        : 'text_checkbox_with_text'
                    }
                    style={{ marginLeft: '10px' }}
                  >
                    Показывать уведомление при отсутствии страховки у водителя
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '30px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Switch
                    onChange={(e) => setInsurance_engine_block(!insurance_engine_block)}
                    checked={insurance_engine_block}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={36}
                    offColor={'#E7ECFB'}
                    onColor={'#6486FB'}
                    activeBoxShadow={'0 0 2px 3px #6486FB'}
                  />
                  <div
                    className={
                      insurance_engine_block
                        ? 'text_checkbox_with_text active'
                        : 'text_checkbox_with_text'
                    }
                    style={{ marginLeft: '10px' }}
                  >
                    Автоматически глушить двигатель авто при отсутствии страховки у водителя
                  </div>
                </div>
              </>
            )}
          </SlideDown>
        </div>
        <div style={{ marginTop: '30px' }}>
          {status_save_edit_btn ? (
            <>
              {status_save_edit_btn == 'success' ? (
                <Fake_btn_defolt
                  color={'#0acf97'}
                  text_color={'#FFFFFF'}
                  hover_color={'#0acf97'}
                  text={'Сохранено!'}
                  height={'40px'}
                  width={'250px'}
                />
              ) : (
                <Fake_btn_defolt
                  color={'#fa5c7c'}
                  text_color={'#FFFFFF'}
                  hover_color={'#fa5c7c'}
                  text={'Ошибка!'}
                  height={'40px'}
                  width={'250px'}
                />
              )}
            </>
          ) : (
            <Btn_defolt
              color={'#0acf97'}
              text_color={'#FFFFFF'}
              hover_color={'#07b584'}
              text={'Сохранить изменения'}
              height={'40px'}
              width={'250px'}
              onClick={save_edit}
            />
          )}
        </div>
      </div>
    </div>
  )
}
