/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useContext } from 'react'

import '../../styles/park_settings/main_park_settings.css'

import { useCookies } from 'react-cookie'
import axios_api from '../../API/axios_api'
import { ParkSettingsContext } from '../../context/index'
import Settings_list from './settings_list.jsx'
import Groups_settings from './groups_settings/groups_settings.jsx'
import Users_settings from './users_settings/users_settings.jsx'
import Refill_settings from './refill_settings/refill_settings.jsx'
import Aggregators_settings from './aggregators_settings/aggregators_settings.jsx'
import Bills_settings from './bills_settings/bills_settings.jsx'
import Driving_quality from './driving_quality/driving_quality.jsx'
import Geolocation from './geolocation/geolocation.jsx'
import Inspections from './inspections/inspections.jsx'
import user_loaded from '../../actions/users_actions.js'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import Geozones from './geozones/geozones.jsx'
import Insurance from './insurance/insurance.jsx'

export default function Draw_park_settings() {
  const dispatch = useDispatch()
  let { park_settings_object } = useContext(ParkSettingsContext)
  const [cookies] = useCookies(['csrftoken'])
  const user_current = useSelector((state) => state.user_current)

  const [groups, setGroups] = useState(null)
  const [inspectionsGroups, setInspectionsGroups] = useState(null)
  const [cars_delailed, setCars_delailed] = useState(null)
  const [users, setUsers] = useState(null)
  const [aggregators, setAggregators] = useState(null)
  const [settings_current, setSettings_current] = useState(null)
  const [bills, setBills] = useState(null)
  const [cars_models, setCars_models] = useState([])
  const [cars_brands, setCars_brands] = useState([])

  const [setting_page, setSetting_page] = useState('users')

  useEffect(() => {
    check_url()
    update_all()
  }, [])

  function check_url() {
    const queryParams = new URLSearchParams(window.location.search)
    const is_bills = queryParams.get('bills')
    //console.log('car_id_show : ', car_id_open)
    if (is_bills == 'true') {
      const url = new URL(document.location)
      const searchParams = url.searchParams
      searchParams.delete('bills')
      window.history.pushState({}, '', url.toString())
      setSetting_page('bills')
    }
  }

  if (park_settings_object.first_park_settings) {
    setInterval(() => {
      update_all()
    }, 30000)
    park_settings_object.first_park_settings = false
  }

  async function update_all() {
    get_settings_current()
    get_groups()
    get_cars_detailed()
    get_users()
    get_inspections_groups()
    get_aggregators()
    get_bills()
    get_cars_brands_and_models()
    get_user_info()
  }

  let settings_access = false
  if (user_current) {
    if (user_current.permissions.includes('SETTINGS')) {
      settings_access = true
    }
  }

  if (!settings_access) {
    return <div className='div_main_park_settings'>У Вас нет прав доступа к настройкам парка.</div>
  }

  if (setting_page == 'groups') {
    return (
      <div className='div_main_park_settings'>
        <Settings_list setting_page={setting_page} setSetting_page={setSetting_page} />
        <Groups_settings groups={groups} cars_delailed={cars_delailed} update_all={update_all} />
      </div>
    )
  }

  if (setting_page == 'users') {
    return (
      <div className='div_main_park_settings'>
        <Settings_list setting_page={setting_page} setSetting_page={setSetting_page} />
        <Users_settings users={users} groups={groups} update_all={update_all} />
      </div>
    )
  }

  if (setting_page == 'inspections') {
    return (
      <div className='div_main_park_settings'>
        <Settings_list setting_page={setting_page} setSetting_page={setSetting_page} />
        <Inspections
          cars_delailed={cars_delailed}
          inspectionsGroups={inspectionsGroups}
          update_all={update_all}
          cars_models={cars_models}
          cars_brands={cars_brands}
        />
      </div>
    )
  }

  if (setting_page == 'refill') {
    return (
      <div className='div_main_park_settings'>
        <Settings_list setting_page={setting_page} setSetting_page={setSetting_page} />
        <Refill_settings payment_info={settings_current} update_all={update_all} />
      </div>
    )
  }

  if (setting_page == 'aggregators') {
    return (
      <div className='div_main_park_settings'>
        <Settings_list setting_page={setting_page} setSetting_page={setSetting_page} />
        <Aggregators_settings aggregators={aggregators} update_all={update_all} />
      </div>
    )
  }

  if (setting_page == 'insurance') {
    return (
      <div className='div_main_park_settings'>
        <Settings_list setting_page={setting_page} setSetting_page={setSetting_page} />
        <Insurance settings_current={settings_current} update_all={update_all} />
      </div>
    )
  }

  if (setting_page == 'bills') {
    return (
      <div className='div_main_park_settings'>
        <Settings_list setting_page={setting_page} setSetting_page={setSetting_page} />
        <Bills_settings
          user_current={user_current}
          update_all={update_all}
          cars={cars_delailed}
          bills={bills}
          settings_current={settings_current}
        />
      </div>
    )
  }

  if (setting_page == 'driving_quality') {
    return (
      <div className='div_main_park_settings'>
        <Settings_list setting_page={setting_page} setSetting_page={setSetting_page} />
        <Driving_quality settings_current={settings_current} update_all={update_all} />
      </div>
    )
  }

  if (setting_page == 'geozones') {
    return (
      <div className='div_main_park_settings'>
        <Settings_list setting_page={setting_page} setSetting_page={setSetting_page} />
        <Geozones settings_current={settings_current} update_all={update_all} />
      </div>
    )
  }

  if (setting_page == 'geolocation') {
    return (
      <div className='div_main_park_settings'>
        <Settings_list setting_page={setting_page} setSetting_page={setSetting_page} />
        <Geolocation settings_current={settings_current} update_all={update_all} />
      </div>
    )
  }

  async function get_groups() {
    let groups_new = await axios_api.get_groups(cookies.csrftoken)
    console.log('park_settings new groups : ', groups_new)
    setGroups(groups_new)
  }

  async function get_cars_brands_and_models() {
    let cars_brands_and_models = await axios_api.get_cars_brands_and_models(cookies.csrftoken)
    console.log('park_settings new cars_brands_and_models : ', cars_brands_and_models)
    if (cars_brands_and_models) {
      setCars_models(cars_brands_and_models.models)
      setCars_brands(cars_brands_and_models.brands)
    }
  }

  async function get_inspections_groups() {
    let inspections_groups = await axios_api.get_inspections_groups(cookies.csrftoken)
    console.log('park_settings new inspections_groups : ', inspections_groups)
    setInspectionsGroups(inspections_groups)
  }

  async function get_cars_detailed() {
    let cars = await axios_api.get_cars_detailed(cookies.csrftoken)
    console.log('park_settings new cars detailed : ', cars)
    setCars_delailed(cars)
  }

  async function get_users() {
    let new_users = await axios_api.get_users(cookies.csrftoken)
    console.log('park_settings new_users : ', new_users)
    setUsers(new_users)
  }

  async function get_aggregators() {
    let new_aggregators = await axios_api.get_aggregators(cookies.csrftoken)
    console.log('park_settings new_aggregators : ', new_aggregators)
    setAggregators(new_aggregators)
  }

  async function get_settings_current() {
    let new_settings_current = await axios_api.get_settings_current(cookies.csrftoken)
    console.log('park_settings new_settings_current : ', new_settings_current)
    setSettings_current(new_settings_current)
  }

  async function get_user_info() {
    let user = await axios_api.get_user_info(cookies.csrftoken)
    dispatch(user_loaded(user))
  }

  async function get_bills() {
    let new_bills = []
    let payments = await axios_api.get_payments(cookies.csrftoken)
    let invoices = await axios_api.get_invoices(cookies.csrftoken)

    for (let payment of payments) {
      payment.use_acquiring = true
      new_bills.push(payment)
    }

    for (let invoice of invoices) {
      invoice.use_acquiring = false
      new_bills.push(invoice)
    }

    new_bills.sort(function (a, b) {
      let date_a = moment(a.date, 'YYYY-MM-DDTHH:mm:ss')
      let date_b = moment(b.date, 'YYYY-MM-DDTHH:mm:ss')

      return date_b.valueOf() - date_a.valueOf()
    })

    console.log('park_settings get_bills : ', new_bills)
    setBills(new_bills)
  }
}
