import React, { useState } from 'react'
import '../../../styles/routes_map/car_table_zone.css'
import car_data from '../../../API/car_data'
import Table_item from './car_table_add_cars_to_zone_item'

export default function Car_table_add_cars_to_zone(props) {
  const [render_table, setRender_table] = useState(false)

  let searched_array = []

  props.cars.map((car) => {
    const isCarInGroup = car.car_group.includes(Number(props.group_sort_id))
    if (!props.search) {
      if (props.group_sort_id == 'ALL' || isCarInGroup) {
        searched_array.push(car)
      }
    } else {
      if (
        car.car_number &&
        car.car_number.toUpperCase().includes(props.search.toUpperCase()) &&
        (props.group_sort_id == 'ALL' || isCarInGroup)
      ) {
        searched_array.push(car)
      }
    }
  })

  function check_checked(car_id) {
    if (!props.geozone.cars) {
      return false
    }
    if (props.add_car_to_zone.includes(car_id)) {
      return 'checked'
    } else {
      return 'unchecked'
    }
  }

  return (
    <div>
      {searched_array.length ? (
        <>
          {searched_array.map((car) => (
            <Table_item
              car_img={car_data.get_svg_car(car.color)}
              modal_add_car_to_zone_active={props.modal_add_car_to_zone_active}
              car={car}
              checked={check_checked(car.id)}
              key={car.id}
              add_car_to_zone={props.add_car_to_zone}
              setAdd_car_to_zone={props.setAdd_car_to_zone}
              setRender_table={setRender_table}
            />
          ))}
        </>
      ) : (
        <div className='no_cars'>Нет авто</div>
      )}
    </div>
  )
}
