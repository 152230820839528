import React, { useEffect, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import arrow_input from '../../styles/icons/arrow_input.svg'
import arrow_blue_input from '../../styles/icons/arrow_blue_input.svg'
import arrow_white_input from '../../styles/icons/arrow_white_input.svg'

export default function Groups_cars_filter(props) {
  const [open_select, setOpen_select] = useState(false)
  const [groupName, setGroupName] = useState('')
  const groups = props.groups || []
  const cars = props.cars || []

  function set_group_id(e) {
    let id = e.currentTarget.getAttribute('data_id')
    props.setGroup_sort_id(id)
    setOpen_select(false)
  }

  function open_close_select() {
    setOpen_select(!open_select)
  }

  function handleClickAway() {
    setOpen_select(false)
  }

  useEffect(() => {
    let group_name = props.text_all
    if (props.group_sort_id != 'ALL') {
      for (let group of groups) {
        if (props.group_sort_id == group.id) {
          if (group.name.length > 15) {
            group_name = group.name.substr(0, 15) + '...'
          } else {
            group_name = group.name
          }
          group_name = group_name + ' (' + group.cars.length + ')'
        }
      }
    }
    setGroupName(group_name)
  }, [props.group_sort_id])

  if (props.type == 'aggregators') {
    for (let group of groups) {
      group.cars = []
      for (let car of cars) {
        if (car.aggregator == group.id) {
          group.cars.push(car.aggregator)
        }
      }
    }
  }

  let class_name_for_select_btn
  let arrow_for_select_btn
  if (open_select) {
    class_name_for_select_btn = 'select_cars_btn_groups_cars_filter opened'
    arrow_for_select_btn = arrow_blue_input
  } else {
    if (props.group_sort_id == 'ALL') {
      class_name_for_select_btn = 'select_cars_btn_groups_cars_filter'
      arrow_for_select_btn = arrow_input
    } else {
      class_name_for_select_btn = 'select_cars_btn_groups_cars_filter active'
      arrow_for_select_btn = arrow_white_input
    }
  }

  return (
    <div
      className=''
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <div
        className={
          props.group_sort_id == 'ALL'
            ? 'all_cars_btn_groups_cars_filter active'
            : 'all_cars_btn_groups_cars_filter'
        }
        data_id={'ALL'}
        onClick={(e) => set_group_id(e)}
      >
        <span>Все ({cars.length})</span>
      </div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={''}>
          <div className={class_name_for_select_btn} onClick={open_close_select}>
            <span>{groupName}</span>
            <img
              src={arrow_for_select_btn}
              className={
                class_name_for_select_btn === 'select_cars_btn_groups_cars_filter opened'
                  ? 'select_cars_btn_groups_cars_filter_img'
                  : ''
              }
              alt=''
            />
          </div>
          <div className={open_select ? 'groups_select active' : 'groups_select'}>
            {groups.map((group) => (
              <div
                className='groups_select_item'
                key={group.id}
                data_id={group.id}
                onClick={(e) => set_group_id(e)}
              >
                <span>
                  {group.name} ({group.cars.length})
                </span>
              </div>
            ))}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  )
}
